@media (max-width: 425px) {
  .navbar-brand img {
    width: 50%;
    float: left;
  }

  .navbar>.container {
    flex-wrap: nowrap !important;
  }
}


.nav-img {
  width: 30px;
}

.navbar.scroll {
  background: rgba(255, 255, 255, 0.4);
}

.container--items-menu {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-right: 50px;
  grid-column-gap: 30px;
}

.navbar-toggler {
  border: 3px solid #fac71f;
}

.navbar-toggler:focus {
  box-shadow: 0px 0px 10px #7a097c !important;
}

.navbar-toggler:hover {
  box-shadow: 0px 0px 10px #7a097c !important;
}

.navbar-toggler-icon {
  background-image: url("../../assets/images/menu.png") !important;
}

.item--menu {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 1rem;
  font-family: "OpenSans-normal";
}

.item--menu p {
  margin: 0 5px;
}

.offcanvas-title {
  font-family: "TitanOne-Regular";
}